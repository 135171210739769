































import { Vue, Prop, Component } from 'vue-property-decorator'

/**
 * A Vue component that renders an input field with a character counter.
 * The input can be either a textarea or a text input, depending on the `inputType` prop.
 * It displays a character counter and shows an error message if the input exceeds the maximum allowed characters.
 * If an external validation error message is provided via the `error` prop, it will be displayed instead of the character counter.
 */
@Component({
  model: {
    prop: 'value', // The prop that v-model will bind to
    event: 'input' // The event that v-model will emit
  }
})
export default class InputWithCharacterCounter extends Vue {
  /**
   * The value for the input field, which will be bound to v-model.
   * @type {string}
   */
  @Prop({ required: true }) readonly value!: string

  /**
   * The type of input to render, either 'text' or 'textarea'.
   * @type {string}
   */
  @Prop({
    required: true,
    validator: (value: string) => ['text', 'textarea'].includes(value)
  })
  readonly inputType!: string

  /**
   * The maximum number of characters allowed in the input field.
   * @type {number}
   */
  @Prop({ required: true }) readonly maxLength!: number

  /**
   * An external validation error message to display.
   * If provided, this message will be shown instead of the character counter.
   * @type {string | undefined}
   */
  @Prop({ required: false }) readonly error!: string

  /**
   * A local copy of the input model, used for two-way binding.
   * @type {string}
   */
  get localInputModel(): string {
    return this.value
  }

  set localInputModel(newValue: string) {
    this.$emit('input', newValue)
  }

  /**
   * Calculates the current number of characters in the input field.
   * @returns {number} The current character count.
   */
  get currentCharacterCount(): number {
    return this.localInputModel.length
  }

  /**
   * Calculates the number of characters remaining or over the limit.
   * @returns {number} The computed counter value.
   */
  get computedCounter(): number {
    return this.maxLength - this.currentCharacterCount
  }

  /**
   * Determines if the input has exceeded the maximum character limit.
   * @returns {boolean} True if the input is over the limit, false otherwise.
   */
  get isOverLimit(): boolean {
    return this.currentCharacterCount > this.maxLength
  }

  /**
   * Generates the message to display in the character counter label.
   * The message varies depending on whether the input is empty, within the limit, or over the limit.
   * @returns {string} The character counter message.
   */
  get counterMessage(): string {
    if (this.currentCharacterCount === 0) {
      return `${this.maxLength} characters allowed`
    } else if (this.isOverLimit) {
      return `${Math.abs(this.computedCounter)} characters over limit`
    } else {
      return `${this.computedCounter} characters left`
    }
  }
}
