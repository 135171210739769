import { render, staticRenderFns } from "./inputWithCharacterCounter.vue?vue&type=template&id=139c356d&scoped=true&"
import script from "./inputWithCharacterCounter.vue?vue&type=script&lang=ts&"
export * from "./inputWithCharacterCounter.vue?vue&type=script&lang=ts&"
import style0 from "./inputWithCharacterCounter.vue?vue&type=style&index=0&id=139c356d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "139c356d",
  null
  
)

export default component.exports