


















































































































































































































import { Component, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import { ScreenText } from '@/lang/ScreenText'
import OffCanvas from '@/commoncomponents/OffCanvas.vue'
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue'
import { CurrencyInput, CurrencyInputOptions } from 'vue-currency-input'
import APP_CONST from '@/constants/AppConst'
import APP_UTILITIES from '@/utilities/commonFunctions'
import { DropdownListItem, DropdownListOptions } from '@/ui-components/dropdownListBx/types'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { VeeValidateProviderMode } from '@/Model/forms/types'
import { ConvenienceFeeGetResponse, ConvenienceFeeType } from '@/Model/payments/types'
import paymentsModule from '@/store/modules/Payments/module'
import { Status } from '@/Model/shared/types'
import { ProviderInstance } from 'vee-validate/dist/types/types'
import ProgressButton from '@/commoncomponents/progressButton/progressButton'
import ChangeFeeModal from '@/popupcomponents/payments/ChangeFeeModal.vue'
import {
  fixedAmountFeeMinValueValidator,
  fixedAmountFeeRequiredValidator,
  feeNameRequiredValidator,
  customFeeSelectionRequiredValidator,
  percentInputRequiredValidator,
  percentInputBetweenValidator,
  percentInputTwoDecimalPlacesValidator,
  maxCharsInputValidator
} from '@/validators/payments/validators'
import { percentToNumber } from '@/services/payments/helpers'
import InputWithCharacterCounter from '@/ui-components/inputWithCharacterCounter/inputWithCharacterCounter.vue'

@Component({
  components: {
    OffCanvas,
    DropdownList,
    CurrencyInput,
    ValidationObserver,
    ValidationProvider,
    ProgressButton,
    ChangeFeeModal,
    InputWithCharacterCounter
  }
})
export default class CustomizeFee extends Vue {
  @PropSync('isCustomizeFeeVisible', { type: Boolean, required: true })
  isCustomizeFeeVisibleSync!: boolean
  @Ref('formObserverRef') readonly formObserverRef?: InstanceType<typeof ValidationObserver>
  @Ref('nameObserverRef') readonly nameObserverRef?: ProviderInstance
  @Ref('percentInputObserverRef') readonly percentInputObserverRef?: ProviderInstance
  @Ref('fixedAmountObserverRef') readonly fixedAmountObserverRef?: ProviderInstance

  private readonly accountId = APP_UTILITIES.getAccountId()
  readonly percentInputVid = 'percentInput'
  readonly fixedAmountInputVid = 'fixedAmountInput'

  readonly screenText = new ScreenText()
  readonly currencyOptions: CurrencyInputOptions = APP_CONST.CURRENCY_OPTIONS
  readonly nameText = this.screenText.getScreenText('LABEL_NAME').toLowerCase()
  readonly initialCustomFeeTemplate = {
    id: 0,
    name: '',
    description: '',
    status: Status.Active,
    feeType: null,
    feeValue: 0
  } as Omit<ConvenienceFeeGetResponse, 'feeType'> & { feeType: ConvenienceFeeType | null }
  payments = paymentsModule
  isPercentRateDisplayed = false
  isFixedAmountDisplayed = false
  isValidationForProgress = false
  convenienceFeeType = ConvenienceFeeType
  validationMode = VeeValidateProviderMode
  percentInput: number | string = 0
  fixedAmountInput: number | null = 0 //Must be nullable for the CurrencyInput, which can null the field momentarily
  customFee = { ...this.initialCustomFeeTemplate }
  isChangeFeeModalVisible = false
  MAX_DESCRIPTION_LENGTH = 100

  get fixedAmountInputRules(): Record<string, any> {
    return {
      fixed_amount_min_value:
        this.customFee.feeType == ConvenienceFeeType.FixedAmount ? 0.01 : false,
      fixed_amount_required: this.customFee.feeType == ConvenienceFeeType.FixedAmount
    }
  }

  get percentBasedInputRules(): Record<string, any> {
    return {
      percent_input_between:
        this.customFee.feeType == ConvenienceFeeType.Percentage ? { min: 0.01, max: 100 } : false,
      percent_input_required: this.customFee.feeType == ConvenienceFeeType.Percentage,
      percent_input_decimal_length: true
    }
  }

  get saveProgressButtonState(): number {
    if (!this.payments.isSavingCustomFee && !this.payments.didSavingCustomFeeFail) {
      return 200
    } else if (!this.payments.isSavingCustomFee && this.payments.didSavingCustomFeeFail) {
      return 400
    } else {
      return 0
    }
  }

  get saveButtonText(): string {
    return this.screenText.getScreenText('BTN_SAVE')
  }

  private selectATaxRateDropdownItem: DropdownListItem = {
    id: 0,
    value: this.screenText.getScreenText('SESSION_ADD_EDIT_TAX_DEFAULT')
  }

  async fetchAllTaxRates() {
    if (this.accountId) {
      await this.payments.getTaxRates(this.accountId)
    }
  }

  async onSelectTax(selection: DropdownListItem) {
    if (selection.id == 0) {
      this.customFee.taxRate = null
    } else {
      const oneBasedDropdownIndex = selection.id
      const convertedZeroBasedTaxRateIndex = oneBasedDropdownIndex - 1
      this.customFee.taxRate = this.payments.taxRates[convertedZeroBasedTaxRateIndex]
    }
  }

  get taxDropdownOptions(): DropdownListOptions {
    let dropdownList: Array<DropdownListItem> = [this.selectATaxRateDropdownItem]
    let selectedTaxRate = this.selectATaxRateDropdownItem

    dropdownList = dropdownList.concat(
      this.payments.taxRates.map((taxRate, index) => {
        const zeroBasedIndex = index
        const convertedOneBasedIndexForDropdown = zeroBasedIndex + 1 // Need to have an Number id for the bx dropdown
        const taxRateDropdownItemForIndex: DropdownListItem = {
          id: convertedOneBasedIndexForDropdown,
          value: `${taxRate.displayName} ${this.screenText.getScreenText(
            'LABEL_TAX_RATE_JOINING_TEXT'
          )} ${taxRate.percentage}%`
        }

        if (this.customFee.taxRate && this.customFee.taxRate.id === taxRate.id) {
          selectedTaxRate = taxRateDropdownItemForIndex
        }
        return taxRateDropdownItemForIndex
      })
    )

    return {
      id: selectedTaxRate.id,
      singleSelect: true,
      showSelectLabel: false,
      value: selectedTaxRate.value,
      dropdownList
    }
  }

  forceNumberInput = APP_UTILITIES.forceNumberInput

  onFixedAmountBlur() {
    if (!this.fixedAmountInput) {
      this.fixedAmountInput = 0
    }
  }

  displayRadioInputError(): string | null {
    let error: string | null = null
    if (this.formObserverRef) {
      const percentRadioError =
        this.formObserverRef.errors[this.percentInputVid] &&
        this.formObserverRef.errors[this.percentInputVid][0]
      const fixedAmountRadioError =
        this.formObserverRef.errors[this.fixedAmountInputVid] &&
        this.formObserverRef.errors[this.fixedAmountInputVid][0]
      const radioError = percentRadioError || fixedAmountRadioError
      if (radioError) {
        error = radioError
      }
    }
    return error
  }

  created() {
    this.onCreated()
  }

  onCreated() {
    this.registerValidators()
    this.fetchAllTaxRates()
  }

  onPercentInputFocus(event: FocusEvent) {
    const targetElement = event.target as HTMLInputElement
    this.percentInput = percentToNumber(targetElement.value)
  }

  addPercentSignToPercentInput() {
    const updatedInput = percentToNumber(`${this.percentInput}`)
    this.percentInput = `${Number(updatedInput)}%`
  }

  onCancel() {
    this.onClose()
    this.isCustomizeFeeVisibleSync = false
  }

  onClose() {
    this.resetInputs()
  }

  @Watch('isCustomizeFeeVisible')
  onVisibleChange(visible: boolean) {
    if (visible) {
      this.setCustomFee()
      this.addPercentSignToPercentInput()
    }
  }

  setCustomFeeValues() {
    if (this.isFixedAmountDisplayed) {
      this.customFee.feeValue = this.fixedAmountInput !== null ? this.fixedAmountInput : 0
      this.customFee.feeType = ConvenienceFeeType.FixedAmount
    } else {
      this.customFee.feeValue = percentToNumber(`${this.percentInput}`)
      this.customFee.feeType = ConvenienceFeeType.Percentage
    }

    //Sibling component FeeDetails changes the paymentsModule.customFee.status when toggled
    if (paymentsModule.customFee) {
      this.customFee.status = paymentsModule.customFee.status
    }
  }

  setCustomFee() {
    this.customFee = paymentsModule.customFee
      ? { ...paymentsModule.customFee }
      : { ...this.initialCustomFeeTemplate }

    if (this.customFee.feeType == ConvenienceFeeType.FixedAmount) {
      this.fixedAmountInput = this.customFee.feeValue
      this.percentInput = this.initialCustomFeeTemplate.feeValue
      this.isFixedAmountDisplayed = true
      this.isPercentRateDisplayed = false
    } else if (this.customFee.feeType == ConvenienceFeeType.Percentage) {
      this.percentInput = this.customFee.feeValue
      this.fixedAmountInput = this.initialCustomFeeTemplate.feeValue
      this.isFixedAmountDisplayed = false
      this.isPercentRateDisplayed = true
    }
  }

  private async canSave(): Promise<boolean> {
    if (this.formObserverRef) {
      await this.formObserverRef.validate()
    }

    return (
      !(paymentsModule.isSavingCustomFee || paymentsModule.isLoadingCustomFee) &&
      !!this.formObserverRef &&
      this.formObserverRef.flags.valid
    )
  }

  private resetInputs() {
    for (const key in this.customFee) {
      const asKeyOf = key as keyof ConvenienceFeeGetResponse
      this.customFee = {
        ...this.customFee,
        [asKeyOf]: paymentsModule.customFee
          ? paymentsModule.customFee[asKeyOf]
          : this.initialCustomFeeTemplate[asKeyOf]
      }
    }

    if (this.formObserverRef) {
      this.formObserverRef.reset()
    }

    this.resetFeeInputs()
  }

  private resetFeeInputs() {
    this.percentInput =
      this.customFee.feeType == ConvenienceFeeType.Percentage ? this.customFee.feeValue : 0
    this.fixedAmountInput =
      this.customFee.feeType == ConvenienceFeeType.FixedAmount ? this.customFee.feeValue : 0
  }

  async onSave() {
    if (!(await this.canSave())) {
      return
    }

    this.isValidationForProgress = false

    this.setCustomFeeValues()

    const updatedFee = {
      ...this.customFee,
      feeType: this.customFee.feeType! //We can assert that feeType != null due to the canSave method
    } as ConvenienceFeeGetResponse

    await paymentsModule.updateCustomFee(updatedFee)

    this.setCustomFee()
    this.isValidationForProgress = false
    this.isCustomizeFeeVisibleSync = false
  }

  closeChangeFeeModal() {
    this.isChangeFeeModalVisible = false
  }

  confirmChangeFee() {
    this.closeChangeFeeModal()
    this.onSave()
  }

  beforeOnSave() {
    if (paymentsModule.customFee) {
      if (this.formObserverRef && this.formObserverRef.flags.changed) {
        this.isChangeFeeModalVisible = true
      } else {
        this.onCancel()
      }
    } else {
      this.onSave()
    }
  }

  displayPercentRate() {
    this.isPercentRateDisplayed = true
    this.isFixedAmountDisplayed = false
  }

  displayFixedAmount() {
    this.isFixedAmountDisplayed = true
    this.isPercentRateDisplayed = false
  }

  handleDescriptionChange(value: string) {
    this.customFee.description = value
  }

  private registerValidators() {
    fixedAmountFeeMinValueValidator(this.screenText.getScreenText('CUSTOM_FEE_FIXED_AMOUNT_ERROR'))
    fixedAmountFeeRequiredValidator(this.screenText.getScreenText('CUSTOM_FEE_FIXED_AMOUNT_ERROR'))
    percentInputBetweenValidator(this.screenText.getScreenText('CUSTOM_FEE_PERCENT_ERROR'))
    feeNameRequiredValidator(
      this.screenText.getScreenText('REQUIRED_INPUT_FIELD_ERROR').replace('{field}', this.nameText)
    )
    customFeeSelectionRequiredValidator(
      this.screenText.getScreenText('CUSTOM_FEE_SELECTION_REQUIRED_ERROR')
    )
    percentInputRequiredValidator(this.screenText.getScreenText('CUSTOM_FEE_PERCENT_ERROR'))
    percentInputTwoDecimalPlacesValidator(this.screenText.getScreenText('CUSTOM_FEE_PERCENT_ERROR'))
    maxCharsInputValidator()
  }
}
